import React from 'react'

const CentClock: React.FC<React.SVGProps<SVGSVGElement>> = ({ ...props }) => {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 16V12H12V16C12 16.2457 12.2251 16.7373 13.2045 17.2269C14.1241 17.6868 15.4646 18 17 18C18.5354 18 19.8759 17.6868 20.7955 17.2269C21.7749 16.7373 22 16.2457 22 16V12H24V16C24 17.4113 22.8821 18.4197 21.69 19.0158C20.4381 19.6417 18.7786 20 17 20C15.2214 20 13.5619 19.6417 12.31 19.0158C11.1179 18.4197 10 17.4113 10 16Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 20V16H12V20C12 20.2457 12.2251 20.7373 13.2045 21.2269C14.1241 21.6868 15.4646 22 17 22C18.5354 22 19.8759 21.6868 20.7955 21.2269C21.7749 20.7373 22 20.2457 22 20V16H24V20C24 21.4113 22.8821 22.4197 21.69 23.0158C20.4381 23.6417 18.7786 24 17 24C15.2214 24 13.5619 23.6417 12.31 23.0158C11.1179 22.4197 10 21.4113 10 20Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M13.2046 10.7731C12.2251 11.2628 12 11.7544 12 12C12 12.2456 12.2251 12.7372 13.2046 13.2269C14.1242 13.6867 15.4647 14 17 14C18.5353 14 19.8758 13.6867 20.7954 13.2269C21.7749 12.7372 22 12.2456 22 12C22 11.7544 21.7749 11.2628 20.7954 10.7731C19.8758 10.3133 18.5353 10 17 10C15.4647 10 14.1242 10.3133 13.2046 10.7731ZM12.3101 8.98425C13.5621 8.3583 15.2216 8 17 8C18.7784 8 20.4379 8.3583 21.6899 8.98425C22.8819 9.5803 24 10.5887 24 12C24 13.4113 22.8819 14.4197 21.6899 15.0157C20.4379 15.6417 18.7784 16 17 16C15.2216 16 13.5621 15.6417 12.3101 15.0157C11.1181 14.4197 10 13.4113 10 12C10 10.5887 11.1181 9.5803 12.3101 8.98425Z"
      />
      <path fillRule="evenodd" clipRule="evenodd" d="M9 4V9H4V7H7V4H9Z" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 2C4.68629 2 2 4.68629 2 8C2 11.3137 4.68629 14 8 14H9V16H8C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C11.6255 0 14.6858 2.41145 15.6685 5.71596L15.9536 6.67448L14.0365 7.24455L13.7515 6.28604C13.0142 3.80655 10.7165 2 8 2Z"
      />
      <defs>
        <rect width="24" height="24" fill="white" />
      </defs>
    </svg>
  )
}

export default CentClock
